






































import { UploadAttachmentWithThumbnail } from '@/models/generic';
import { ToastProgrammatic } from 'buefy';
import moment from 'moment';
import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
@Component({})
export default class Upload extends Vue {
  currentFile: File | null = null;
  videoThumbnail: File | null = null;
  isFileSizeValid = true;
  isFileTypeValid = true;
  previousFileUrl = '';
  canPlayListenerEventRemoved = false;

  get imageUrl() {
    return this.currentFile ? URL.createObjectURL(this.currentFile) : null;
  }

  get isFileTypeVideo() {
    return this.currentFile ? this.currentFile.type === 'video/mp4' : false;
  }

  get isPreviousFileTypeVideo() {
    return this.previousFileUrl.length > 0 && this.previousFileUrl.includes(`mp4`);
  }

  get currentFileName() {
    return this.currentFile ? this.currentFile.name : '';
  }

  @Prop({ required: true })
  fileTypes!: string[];

  @Prop({ default: false })
  showDeleteButton!: string[];

  @Prop({ default: 5 })
  fileSizeLimit!: number;

  @Prop()
  previousSelectedFile!: string;

  @Watch(`previousSelectedFile`)
  updateCurrentFile() {
    if (this.previousSelectedFile && this.previousSelectedFile.length > 0) {
      this.previousFileUrl = this.previousSelectedFile;
    }
  }

  //Placeholders
  retrieveThumbnail = () => {};
  removeRetrieveThumbnail = () => {};

  mounted() {
    const video = this.$refs[`videoInput`] as HTMLVideoElement;
    const canvas = document.createElement('canvas');
    this.canPlayListenerEventRemoved = false;
    this.retrieveThumbnail = () => {
      if (this.currentFile?.type.includes(`video/`)) {
        const videoContext = canvas.getContext('2d');
        video.currentTime = 0;
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        videoContext!.drawImage(video, 0, 0);
        canvas.toBlob((blob) => {
          if (blob) {
            const blobFile = new File(
              [blob],
              `generatedThumbnail_${moment(new Date()).toISOString()}.png`,
              {
                type: blob?.type,
              }
            );
            this.uploadThumbnail(blobFile);
          }
        });
        // Remove canplaythrough listener if it is more than the specified duration
        setTimeout(() => {
          this.canPlayListenerEventRemoved = true;
          video.removeEventListener(`canplaythrough`, this.retrieveThumbnail);
        }, 5000);
      }
    };
    this.removeRetrieveThumbnail = () => {
      video.removeEventListener(`canplaythrough`, this.retrieveThumbnail);
      this.canPlayListenerEventRemoved = true;
    };
    video.addEventListener(`canplaythrough`, this.retrieveThumbnail);
    video.addEventListener(`play`, this.removeRetrieveThumbnail);
  }

  beforeDestroy() {
    const video = this.$refs[`videoInput`] as HTMLVideoElement;
    video.removeEventListener(`canplaythrough`, this.retrieveThumbnail);
    video.removeEventListener(`play`, this.removeRetrieveThumbnail);
  }

  checkAttachment(data: File) {
    this.isFileSizeValid = true;
    this.isFileTypeValid = true;

    if (!this.fileTypes.includes(data.type)) {
      ToastProgrammatic.open({
        type: 'is-danger',
        duration: 3000,
        message: `Invalid File Type. Expecting ${this.fileTypes.join(', ')} type`,
      });
      this.isFileTypeValid = false;
      this.currentFile = null;
    }

    // If data is image type
    if (data.type.includes(`image/`)) {
      const fileSize = Math.floor(data.size / Math.pow(1024, 2));
      if (fileSize > this.fileSizeLimit) {
        ToastProgrammatic.open({
          type: 'is-danger',
          duration: 3000,
          message: `Invalid File Size for Images. Expecting maximum of ${this.fileSizeLimit}mb`,
        });
        this.isFileSizeValid = false;
        this.currentFile = null;
      }
    } else if (data.type.includes(`video/`)) {
      const fileSize = Math.floor(data.size / Math.pow(1024, 2));
      if (fileSize > this.fileSizeLimit) {
        ToastProgrammatic.open({
          type: 'is-danger',
          duration: 3000,
          message: `Invalid File Size for MP4. Expecting maximum of ${this.fileSizeLimit}mb`,
        });
        this.isFileSizeValid = false;
        this.currentFile = null;
      }
    }

    // If previous file exists, add back event listener if removed
    if (
      this.isFileTypeValid &&
      this.isFileSizeValid &&
      this.currentFile &&
      this.canPlayListenerEventRemoved
    ) {
      const video = this.$refs[`videoInput`] as HTMLVideoElement;
      video.addEventListener(`canplaythrough`, this.retrieveThumbnail);
    }

    if (this.isFileTypeValid && this.isFileSizeValid) {
      this.updateAttachment(data);
    }
  }

  @Emit(`clear`)
  clearFile() {
    this.currentFile = null;
    this.videoThumbnail = null;
    this.previousFileUrl = '';
    this.updateAttachment(null);
  }

  uploadThumbnail(file: File) {
    this.videoThumbnail = file;
    this.uploadFinalAttachments();
  }

  updateAttachment(data: File | null) {
    this.currentFile = data;
    this.uploadFinalAttachments();
  }

  @Emit(`input`)
  uploadFinalAttachments() {
    const output: UploadAttachmentWithThumbnail = {
      originalFile: this.currentFile,
      thumbnail: this.videoThumbnail,
    };
    return output;
  }
}
